




























































































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {MailingType, PurchaseRecord, XBan} from '~/components/data-class/data-class'
import {randomColor} from '~/utils/random-color'
import copy from 'copy-to-clipboard'
import {QrcodeStream} from 'vue-qrcode-reader'
import {sfLookup, updateLocations} from '~/components/sf-express/sf-location-storage'
import MailedOrderSelect from './mailed-order-select.vue'
import {ShopPermission} from '~/utils/permissions'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import WaybillScannerDialog from '~/components/waybill-scanner-dialog.vue'
import moment from 'moment'

@Component({
    components: {
        QrcodeStream,
        MailedOrderSelect,
        WaybillScannerDialog,
    },
    metaInfo() {
        return {
            title: 'Coupon Purchase Record'
        }
    }
})

export default class PurchaseRecords extends mixins(QueryParserMixin, PaginationMixin) {
    static permission = ShopPermission.ShopPurchaseRecords

    queryDef = [
        {localVar: 'shopItemId', queryStr: 'item', type: QueryType.Number},
        {localVar: 'mailingType', queryStr: 'mailing-type', type: QueryType.Number},
        {localVar: 'notSentOnly', queryStr: 'not-sent', type: QueryType.Boolean},
        {localVar: 'memberId', queryStr: 'member-id'},
        {localVar: 'currentPage', queryStr: 'page', type: QueryType.Number},
    ]

    dialogAddWaybillNumber = false
    MailingType = MailingType

    memberId = ''
    shopItemId: number | '' = ''
    mailingType: MailingType | '' = ''
    notSentOnly: boolean = false

    records: PurchaseRecord[] = []
    recordScanning: PurchaseRecord

    get isMobile() {
        const userAgent = navigator.userAgent
        const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1 // android终端
        const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
        // return isAndroid || isiOS
        return true
    }
    async toPage(n) {
        this.currentPage = n
        await this.getRecords(this.currentPage)
    }
    async getRecords(page = 1) {
        const q: { [key: string]: any } = {
            skip: (page - 1) * this.step,
            number: this.step
        }
        if (this.notSentOnly)
            q['not_sent_only'] = true
        if (this.memberId)
            q['member_id'] = this.memberId
        if (this.mailingType !== '')
            q['mailing_type'] = this.mailingType
        if (this.shopItemId !== '')
            q['shop_item_id'] = this.shopItemId

        const res = await createRequest('/shop/purchase-records/admin', 'get', q).send()
        this.records = res.data.records
        this.totalCount = res.data.count
        this.setQuery()
    }
    async created() {
        this.parseQuery()
        await this.getRecords()
        await updateLocations()
    }

    randomColor(str: string) {
        return randomColor(str)
    }
    async midClicked(mid) {
        if (this.memberId !== mid)
            this.memberId = mid
        else
            this.memberId = ''
        await this.getRecords()
    }

    sendSF(phoneNum, userName, shippingCode) {
        // find shipping data
        const sf = sfLookup[shippingCode]
        const shippingLocation = sf ? sfLookup[shippingCode].address : ''

        let str = ''

        if (userName != undefined) str += userName + '，'
        if (phoneNum != undefined) str += phoneNum + '，'

        str += shippingLocation ? shippingLocation + '（' + shippingCode + '）' : shippingCode

        copy(str, {
            debug: false,
            message: 'Press #{key} to copy',
        })

        this.$message('Coped!!')

        const userAgent = navigator.userAgent
        const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1 // android终端
        const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端

        if (isAndroid || isiOS) {
            window.location.href = 'suyuntonghmto://sf.com'
        }
    }

    refundRecord(record: PurchaseRecord) {
        this.$confirm('Sure to refund the record?', 'Warning', {
            confirmButtonText: 'Refund',
            cancelButtonText: 'Cancel',
            type: 'warning'
        }).then(async () => {
            await createRequest(`/shop/purchase-record/refund`, 'post', {}, {_id: record._id}).send()
            await this.getRecords()
        }).catch(() => {
        })
    }

    startScan(record: PurchaseRecord) {
        this.recordScanning = record
        this.dialogAddWaybillNumber = true
    }

    codeScanned(awbNo: string) {
        createRequest('/shop/purchase-record/send', 'post', {}, {
            _id: this.recordScanning._id,
            waybill_number: awbNo
        }).send()
            .then(() => {
                this.recordScanning.waybill_number = awbNo
            })
            .catch((e) => {
                console.log(e)
                this.$message({
                    message: 'Error ' + JSON.stringify(e),
                    type: 'error'
                })
            })
    }

    orderSelected(record: PurchaseRecord) {
        createRequest('/shop/purchase-record/send', 'post', {}, {
            _id: record._id,
            order_id: record.order_id
        }).send()
            .then((res) => {
                const nRecord: PurchaseRecord = res.data.record
                record.waybill_number = nRecord.waybill_number
                this.$message({
                    message: 'Success',
                    type: 'success'
                })
            })
            .catch((e) => {
                console.log(e)
                this.$message({
                    message: 'Error ' + JSON.stringify(e),
                    type: 'error'
                })
            })
    }

    bannedMsg(xban: XBan) {
        return 'Banned ' + (xban.days >= 999 ? 'forever' : `till ${moment(xban.until).format('YYYY/MM/DD')}`)
    }
}

